<template>
  <div>
    <b-overlay :show="loading">
      <good-table-column-search
        :items="clients.data"
        :columns="columns"
        :total="clients.total"
        :page="page"
        :has-show="true"
        model="client"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => page = p"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import { toLocaleDateString } from '@/filters/report'
import GoodTableColumnSearch from '../table/vue-good-table/GoodTableColumnSearch.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      page: 1,
      loading: false,
      tab_id: 1,
      filterModel: {
        per_page: 50,
        number: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      clients: 'client/GET_ITEMS',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Фамилия'),
          field: 'lastname',
          showField: this.hasPermission('clients.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Имя'),
          field: 'firstname',
          showField: this.hasPermission('clients.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Отчества'),
          field: 'middlename',
          showField: this.hasPermission('clients.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Телефон'),
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Паспорт'),
          field: this.passportField,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('ПИНФЛ'),
          field: 'pin',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Дата'),
          field: 'created_at',
          formatFn: this.dateFormat,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: (this.isAdmin || this.isSeller) ? 'action' : '',
          isContract: this.hasPermission('contracts.store'),
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getClientsAction: 'client/clients',
      destroyAction: 'region/destroy',
      getCitiesAction: 'city/index',
      destroyCityAction: 'city/destroy',
    }),
    passportField(row) {
      return row.doc_series + row.doc_number
    },
    dateFormat(value) {
      return toLocaleDateString(value)
    },
    async getItems() {
      this.loading = true
      await this.getClientsAction({ ...this.filterModel, page: this.page })
      this.items = this.clients
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
